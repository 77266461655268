/* Mixins */
.select-user-component .select-user-wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  line-height: 30px;
  padding: 0 11px;
  height: 32px;
}
.select-user-component .select-user-wrapper .select-user-tag {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  height: 24px;
  margin-top: 3px;
  line-height: 22px;
  padding: 0 5px;
  font-size: 14px;
  margin-right: 3px;
  display: inline-block;
}
.select-user-component .select-user-wrapper .select-user-tag .select-user-icon {
  padding-left: 3px;
  font-size: 10px;
}
.select-user-component .no-user-label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
}